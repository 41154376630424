<template>
  <div id="cesium3dTiles">
    <div id="mapbox3dTilesMap" style="position: absolute;width:100%;height:100%;"></div>

    <div v-if="simpleInfo.length > 0" id="bottom-sheet">
      <p style="margin:5px 0 16px 0;font-size:18px;font-weight:bold;color:white;">{{title}}</p>
      <div class="text-style">{{simpleInfo}}</div>
      <p style="margin-bottom:0;font-size:14px;color:white;">{{shareReason}}</p>
    </div>
  </div>
</template>


<script>

import {getMapboxToken} from "@/utils/utils";
import mapboxgl from "mapbox-gl";
import {MapboxLayer} from "@deck.gl/mapbox"
import {Tile3DLayer} from "@deck.gl/geo-layers"
import {Tiles3DLoader} from '@loaders.gl/3d-tiles'
import {Matrix4} from "@math.gl/core"


export default {
  name: "Mapbox3dTiles",
  mounted() {
    this.infoId = this.$route.query.infoId
    this.initMapbox()
  },
  data(){
    return{
      infoId:'',
      map:'',

      title:"",
      simpleInfo:'',
      shareReason:'',
    }
  },
  methods:{
    async initMapbox() {
      await getMapboxToken()
      mapboxgl.accessToken = this.globalJS.mapboxToken;
      this.map = new mapboxgl.Map({
        container: 'mapbox3dTilesMap',
        center: [0, 0],
        zoom: 3,
        style: this.globalJS.mapboxStyle,
        preserveDrawingBuffer: true,
        localIdeographFontFamily: '',
        projection: {name: 'globe'},
        transformRequest: (url) => {
          if (url.indexOf('https://api.mapbox.com') > -1) {
            return {
              url: url.replace('https://api.mapbox.com', 'https://earth.jl1.cn/mapbox'),
            };
          }
        }
      });
      this.map.on('load', () => {
        // this.map.addSource('mapbox-dem', {
        //   type: 'raster-dem',
        //   url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
        //   tileSize: 512,
        //   maxZoom: 14,
        // });
        this.map.setFog({
          "horizon-blend": 0.1,
          "space-color": 'rgba(0, 0, 0, 1)',
          "star-intensity": 0.15
        });
        // this.map.setTerrain({'source': 'mapbox-dem', 'exaggeration': 1});
        this.map.addLayer({
          id: 'sky',
          type: 'sky',
          paint: {
            'sky-type': 'atmosphere',
            'sky-atmosphere-sun': [0.0, 0.0],
            'sky-atmosphere-sun-intensity': 15,
          }
        });
        this.map.addSource('baseLayer', {
          type: 'raster',
          tiles: [
            this.globalJS.world1mUrl_2021
          ],
          tileSize: 256,
          scheme: 'tms'
        });
        this.map.addLayer({
          id: 'baseLayer',
          type: 'raster',
          source: 'baseLayer'
        })

        this.get3DTilesInfo()
      });
    },

    get3DTilesInfo() {
      this.$http.get('/earthApi/tiles3d-info/getTiles3dInfoById?mainId='+this.infoId,).then(async response => {
        if (response.data.status === 200) {
          let tilePath = response.body.data.tilesUrl
          document.title = response.body.data.title
          this.title = response.body.data.title
          this.simpleInfo = response.body.data.simpleInfo
          this.shareReason = response.body.data.shareReason
          console.log(tilePath)

          //加载3dtiles
          const layer=this.load3DTiles({
            id: "3dTiles-xxxxxx",
            describe:"倾斜摄影",
            url:tilePath,
            layerType: "3DTiles"
          })
          this.map.addLayer(layer)


        }
      })
    },




    load3DTiles(layerConfig) {
      const layer=new MapboxLayer({
        id: layerConfig.id,
        type: Tile3DLayer,
        data: layerConfig.url,
        loader: Tiles3DLoader,
        onTileError: (err) => {
          console.log(err)
        },
        onTilesetLoad: (tileset) => {
          tileset.setProps({ maximumScreenSpaceError: 1 })
          const { cartographicCenter, zoom } = tileset;
          this.viewState = {
            ...this.viewState,
            longitude: cartographicCenter[0],
            latitude: cartographicCenter[1],
            zoom
          }
          const viewState = this.viewState
          //设置x、y、z偏移，有些倾斜摄影模型加载进来不在该位置
          //需要进行位置的调整，但我在进行旋转、和比例缩放的时候不起作用
          //欢迎来指正
          tileset.modelMatrix=new Matrix4().makeTranslation(0, 0, -250)
          this.map.jumpTo({
            center: [viewState.longitude, viewState.latitude],
            zoom: viewState.zoom,
            //bearing: viewState.bearing,
            //pitch: viewState.pitch,
          });

        }
      })
      return layer
    },




  }
}
</script>

<style scoped>
#cesium3dTiles {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: black;
}


#bottom-sheet{
  position: fixed;
  bottom: 0;
  background-color:#38383899;
  width: calc(100% - 40px);
  height: fit-content;
  border-radius: 16px 16px 0px 0px;
  padding:20px;
}

.text-style{
  font-size: 16px;
  color: white
}
</style>