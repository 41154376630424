<template>
  <div id="orderImage">
    <img src="../../assets/OrderImage/商城海报.jpg" width="100%" style="object-fit: cover;z-index: 2" alt="">
<!--    <div style="height: 60px"></div>-->
    <div style="padding: 0px 20px 0 20px;z-index: 900;">
<!--      <img v-if="share === '1'" src="../../assets/OrderImage/mall_logo.png" width="180" height="50" style="object-fit: contain;display: block;margin: 0 auto" alt="">-->
<!--      <div  style="font-size: 48px;color: white;font-family: YouSheBiaoTiHei">海量遥感影像</div>-->
<!--      <div style="font-size: 48px;color: white;font-family: YouSheBiaoTiHei;text-align: right">一触即达</div>-->
<!--      <div class="subTitle" style="margin-top: 15px">服务介绍</div>-->
<!--      <div class="contentArea" style="font-size: 14px;color: white;">-->
<!--        <div style="text-align: left;width: 280px;margin: 0 auto;padding-top: 30px">-->
<!--          吉林一号生态开放商城为您提供“吉林一号”全球海量卫星遥感数据查询订购服务，可按需订购。极具价格优势的一站式全量优质数据，更适应全行业的卫星遥感大数据智能数字引擎。-->
<!--        </div>-->
<!--      </div>-->
<!--      <div style="display: flex;margin-top: 20px">-->
<!--        <intro-card image-name="卫星遥感大数据"  intro="卫星遥感大数据" ></intro-card>-->
<!--        <div style="margin-left: 10px;margin-top: 20px">-->
<!--          <intro-words type="1" content="生态会员采购亚米级存档推扫影像价格低至￥13/km"></intro-words>-->
<!--          <intro-words type="1" content="海量数据触手可得" style="margin-top: 10px"></intro-words>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div style="display: flex;margin-top: 15px;">-->
<!--        <div style="margin-top: 20px">-->
<!--          <intro-words type="2" content="应用行业全覆盖"></intro-words>-->
<!--          <intro-words type="2" content="服务系统可定制" style="margin-top: 10px"></intro-words>-->
<!--          <intro-words type="2" content="生态会员免费试用" style="margin-top: 10px"></intro-words>-->
<!--        </div>-->
<!--        <intro-card image-name="一站式行业解决方案"  intro="一站式行业解决方案" style="margin-left: 10px;"></intro-card>-->
<!--      </div>-->

<!--      <div class="subTitle" >影像类型</div>-->
<!--      <div style="display: flex;flex-wrap: nowrap;justify-content: space-around;margin-top: 20px">-->
<!--        <intro-card image-name="50cm高分辨率"  intro="50cm高分辨率光学影像"></intro-card>-->
<!--        <intro-card image-name="亚米光学推扫"  intro="亚米级光学推扫影像"></intro-card>-->
<!--      </div>-->
<!--      <div style="display: flex;flex-wrap: nowrap;justify-content: space-around;margin-top: 20px">-->
<!--        <intro-card image-name="多光谱"  intro="多光谱影像"></intro-card>-->
<!--        <intro-card image-name="夜光"  intro="夜光/微光影像"></intro-card>-->
<!--      </div>-->
<!--      <div style="display: flex;flex-wrap: nowrap;justify-content: space-around;margin-top: 20px">-->
<!--        <intro-card image-name="凝视"  intro="凝视视频"></intro-card>-->
<!--        <intro-card image-name="惯性空间成像"  intro="惯性空间成像"></intro-card>-->
<!--      </div>-->
      <div class="subTitle">订购意向</div>
      <div class="contentArea">
        <div style="display: flex;width: 295px;margin: 0 auto;padding-top: 30px">
          <div style="font-size: 14px;color: white;line-height:26px;">需求描述：</div>
          <input v-model="discription" class="orderImageInput" placeholder="如：我需要每季度上海市亚米级影像…"/>
        </div>
        <div style="display: flex;width: 295px;margin: 12px auto 0 auto;">
          <div style="font-size: 14px;color: white;line-height:26px;">联系方式：</div>
          <input v-model="userPhoneNum" class="orderImageInput"/>
        </div>

        <div :style="{'display':picCaptchaDisplay}" style="display: flex;width: 295px;margin: 12px auto 0 auto;">
          <div style="font-size: 14px;color: white;line-height:26px;">图形验证码：</div>
          <input v-model="picCaptcha" class="orderImageInput" style="width: 100px"/>
          <img @click="getPicCaptcha" :src="picCaptchaSrc" style="width:70px;margin-left: 10px;cursor:pointer;">
        </div>
        <div style="display: flex;width: 295px;margin: 12px auto 0 auto;">
          <div style="font-size: 14px;color: white;line-height:26px;">输入验证码：</div>
          <input v-model="verifyCode" class="orderImageInput" style="width: 100px"/>
          <div @click="sendVerifyCode()" style="cursor: pointer;line-height:26px;font-size: 14px;color: white;text-align: right;margin-left: 10px">{{sendCodeContent}}</div>
        </div>
      </div>
      <div style="font-size: 12px;color:#979797;margin-top: 10px">*工作人员将在24小时内与您联系，感谢您对长光卫星的支持，您可联系我们的商务经理咨询更多详情。</div>
      <div style="font-size: 12px;color:white;margin-left: 5px">
        <span class="iconfontFamily" style="color: white;font-size:12px;" >&#xe77a;</span>
        马经理：
        <span @click="dial" v-if="share === '0'" style="color: #0349A9;cursor: pointer">18844193183 </span>
        <a v-if="share === '1'" :href="'tel:' + managerPhoneNum"><span style="color: #0349A9;cursor: pointer">{{ managerPhoneNum }} </span>
        </a>
        工作日8:30-17:30
      </div>
      <div @click="commitOrder()" style="width: 126px;height: 36px;margin: 20px auto 20px auto;border-radius: 18px;background: linear-gradient(218.06deg, #124373 0%, #1F418C 100%);color: white;text-align: center;line-height: 36px">提交</div>
      <div style="font-size: 16px;color: white">更多服务可前往“吉林一号”生态开放商城网页版了解体验。</div>
      <div @click="copy" style=";font-size: 16px;color: white;margin-top: 30px;text-align: center">{{mallAddress}}&nbsp;&nbsp;
        <span  class="iconfontFamily" style="color: white;font-size:16px;cursor: pointer" >&#xe77b;</span>
      </div>
      <div style="width: 100%;height: 60px"></div>
    </div>
    <TopShareBar v-if="share === '1'" style="z-index: 1000"/>
  </div>
</template>

<script>
// import IntroCard from "@/views/OrderImage/IntroCard";
// import IntroWords from "@/views/OrderImage/IntroWords";
import TopShareBar from "@/components/TopShareBar";
import {getWxSignature} from "@/utils/shareConfig";
export default {
  name: "OrderImage",
  // components: {TopShareBar, IntroWords, IntroCard},
  components: {TopShareBar},
  mounted() {
    this.share = this.$route.query.share
    getWxSignature("","影像订购")
  },
  data(){
    return {
      share:0,
      image:'../../assets/OrderImage/背景图.png',
      managerPhoneNum:'18844193183',
      mallAddress:'https://www.jl1mall.com/',
      discription:'',
      userPhoneNum:'',
      verifyCode:'',
      countDownNum:60,
      sendCodeContent:'获取验证码',
      clock:null,
      sendingAvailable:true,

      //生成图形验证码
      picCaptcha:"",
      picCaptchaSrc:"",
      picCaptchaDisplay:"none",
    }
  },
  watch:{
    //监听手机号变化
    userPhoneNum(){
      this.checkPicCaptchaShow();
    }
  },
  methods:{
    dial(){
      if(window["Dial"]){
        window["Dial"].postMessage(this.managerPhoneNum);
      }
    },
    copy(){
      console.log('copy')
      this.$copyText(this.mallAddress).then( () => {
        console.log(this.share)
        if(this.share === '0'){
          if(window["MallOrderToast"]){
            window["MallOrderToast"].postMessage('复制成功');
          }
        }else{
          alert('复制成功')
        }
      }, function () {
        alert('复制失败')
      })
    },
    sendVerifyCode(){
      if(!this.sendingAvailable){
        return;
      }
      if(this.userPhoneNum.length!== 11){
        if(this.share === '0'){
          if(window["MallOrderToast"]){
            window["MallOrderToast"].postMessage('手机号格式不正确');
          }
        }else{
          alert('手机号格式不正确')
        }
        return
      }
      // this.sendingAvailable = false
      let data = new FormData()
      data.append('prefix','+86')
      data.append('purpose','authentication')
      data.append('picCaptcha',this.picCaptcha)
      data.append('phone',this.userPhoneNum)
      this.$http.post('/userApi/sendCodeForAction',data).then(response=> {
        console.log(response)
        console.log(response.status)
          if(response.body.code === 200){
            this.clock = window.setInterval(() => {
              this.countDownNum--;
              this.sendCodeContent = "("+this.countDownNum+'s)'
              if (this.countDownNum < 0) {
                //当倒计时小于0时清除定时器
                window.clearInterval(this.clock); //关闭
                this.sendingAvailable = true;
                this.countDownNum = 60;
                this.sendCodeContent = "获取验证码"
              }
            }, 1000);
          }
          else if(response.body.code === 4014){
            if(this.share === '0'){
              if(window["MallOrderToast"]){
                window["MallOrderToast"].postMessage('操作频繁，请1分钟后再试');
              }
            }else{
              alert('操作频繁，请1分钟后再试')
            }
          }
          else if(response.body.code === 4015){
            if(this.share === '0'){
              console.log("传消息给flutter")
              if(window["MallOrderToast"]){
                window["MallOrderToast"].postMessage('图形验证码有误');
              }
            }else{
              alert('图形验证码有误')
            }

          }
        }
      )
    },
    commitOrder(){
      if(this.discription.length<1){
        if(this.share === '0'){
          if(window["MallOrderToast"]){
            window["MallOrderToast"].postMessage('请简述需求');
          }
        }else{
          alert('请简述需求')
        }
        return
      }

      if(this.userPhoneNum.length!== 11){
        if(this.share === '0'){
          if(window["MallOrderToast"]){
            window["MallOrderToast"].postMessage('手机号格式不正确');
          }
        }else{
          alert('手机号格式不正确')
        }
        return
      }

      if(this.verifyCode.length<1){
        if(this.share === '0'){
          if(window["MallOrderToast"]){
            window["MallOrderToast"].postMessage('验证码不能为空');
          }
        }else{
          alert('验证码不能为空')
        }
        return
      }
      //验证逻辑
      let data = new FormData()
      data.append('prefix','+86')
      data.append('requirement',this.discription)
      data.append('phone',this.userPhoneNum)
      data.append('captcha',this.verifyCode)
      this.$http.post('/userApi/jl1-mall/require',data).then(response=> {
        console.log(response)
        if(response.data.code === 200){
          if(this.share === '0'){
            if(window["MallOrderToast"]){
              window["MallOrderToast"].postMessage('提交成功');
            }
          }else{
            alert('提交成功')
          }
          this.discription = ""
          this.userPhoneNum = ""
          this.verifyCode = ""
        }else{
          if(this.share === '0'){
            if(window["MallOrderToast"]){
              window["MallOrderToast"].postMessage('提交失败');
            }
          }else{
            alert('提交失败')
          }
        }
      })

    },


    //检查是否需要展示图形验证码行
    checkPicCaptchaShow(){
      if(this.userPhoneNum.length === 11){
        //手机号合法，展示
        this.picCaptchaDisplay = "flex";
        this.getPicCaptcha();
      }
      else{
        //手机号不合法
        this.picCaptchaDisplay = "none";
        this.picCaptcha = "";
      }
    },
    //获取图形验证码
    getPicCaptcha(){
      let formData = new FormData()
      formData.append('phone',this.userPhoneNum)
      formData.append('prefix',"+86")
      this.$http.post('/userApi/getPhonePicCaptcha', formData).then(resultData => {
        resultData = resultData.data;
        console.log(resultData)
        if(resultData.code === 200){
          this.picCaptchaSrc = resultData.data.picture;
        }
      })
    },
  }
}
</script>

<style scoped>
#orderImage{
  position: relative;
  width: 100%;
  height: max-content;
  z-index: 1;
  background-color: #010311;
}

.subTitle{
  width: 100px;
  height: 30px;
  margin: 40px auto 0 auto;
  background: url(../../assets/OrderImage/标题图.png) center center no-repeat;
  background-size: 100% 100%;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  color: white;
}
.contentArea{
  width: 100%;
  /*height: 150px;*/
  background: url(../../assets/OrderImage/内容框.png) center center no-repeat;
  margin-top: 15px;
  background-size: 100% 100%;
  padding-bottom: 20px;
}

.orderImageInput{
  width:172px;
  height:26px;
  margin-left:5px;
  padding:0 9px;
  border-radius: 4px;
  background: linear-gradient(90deg, #2980E3 0%, #1F418C 100%);
  border: 1px solid #FFFFFF;
  outline:none;
  font-size:11px;
  line-height:32px;
}
</style>