import { render, staticRenderFns } from "./CiteMarker.vue?vue&type=template&id=118a3784&scoped=true&"
import script from "./CiteMarker.vue?vue&type=script&lang=js&"
export * from "./CiteMarker.vue?vue&type=script&lang=js&"
import style0 from "./CiteMarker.vue?vue&type=style&index=0&id=118a3784&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "118a3784",
  null
  
)

export default component.exports