<template>
  <div ref="cityOfSkyPage" style="position: relative;width: 100vw;height: 100vh;background: black" class="OPPOSans">
    <top-share-bar v-if="isShare"></top-share-bar>
    <div :style="{'animation-name':mapAnimationName}" style="position:fixed;top:0;left:0;width:100vw;height:100vh;background:#000000;animation-duration:5s;">
      <div id="map" style="width: 100%;height: calc(100% - 60px);position: absolute;top: 0;left: 0;overflow: hidden;pointer-events: none">
<!--        <RainScene v-if="mapboxInitFinish && weather === 'rain'" :centerCoords="centerCoords" :width="rainRangeWidth" :height="rainRangeHeight"></RainScene>-->
      </div>
      <CiteMarkerEntry v-if="mapboxInitFinish && showMarker" :map="map" :marker-list="spotList"></CiteMarkerEntry>
    </div>
    <canvas v-show="showWeather" id="weather" style="position: absolute;width:100%;height:calc(100% - 60px);pointer-events: none;"></canvas>
<!--    <img v-if="mapboxInitFinish && showWeather && weather === 'Snow'" src="https://market.obs.cn-north-4.myhuaweicloud.com:443/202412301110000004980.gif" style="position: absolute;width:100%;height:calc(100% - 60px);object-fit: cover;z-index: 200"/>-->
    <div style="position: relative;pointer-events: none">
      <div style="height: 65px"></div>
      <div style="width: 100%;font-size: 16px;color: white;display: flex;align-items: center;justify-content: center;height: 16px"></div>
      <div v-if="showLngLat && mapboxInitFinish" class="Oswald" style="height: 75px;width: calc(100% - 50px);padding-left: 25px;padding-right: 25px;margin-top: 10px;display: flex;align-items: center;justify-content: space-between">
        <div style="display: flex;flex-direction: column;align-items: center;justify-content: center;color: white;text-shadow: 0 2px 4px rgba(0,0,0,0.5);width: calc(50% - 25px)">
          <div><span style="font-size: 35px;">{{ currLng.toString().split('.')[0] }}.</span><span style="font-size: 42px;">{{ currLng.toString().split('.')[1] }}°</span></div>
          <div class="OPPOSans">经度</div>
        </div>
        <div style="display: flex;flex-direction: column;align-items: center;justify-content: center;color: white;text-shadow: 0 2px 4px rgba(0,0,0,0.5);width: calc(50% - 25px)">
          <div><span style="font-size: 35px;">{{ currLat.toString().split('.')[0] }}.</span><span style="font-size: 42px;">{{ currLat.toString().split('.')[1] }}°</span></div>
          <div class="OPPOSans">纬度</div>
        </div>
      </div>
    </div>

    <div v-if="!isClearMode" style="position: absolute;bottom: 15%;left: calc(50% - 26px);width: 52px;height: 63px;animation: swing 12s infinite">
      <img height="63" width="52" src="../../assets/CityOfSky/paperPlane.png"/>
    </div>
    <div
        style="position: absolute;bottom: 0;left: 0;right: 0;width: calc(100vw - 24px);background: white;height: 60px;display: flex;align-items: center;justify-content: space-between;padding-right: 12px;padding-left: 12px">
      <div @click="clickButton('经纬坐标')" class="buttonArea">
        <div class="iconfontFamily" style="font-size: 22px" :style="{color: showLngLat ? 'rgba(94,143,255,1)' : 'rgba(166,166,166,1)'}">&#xe601;</div>
        <div style="font-size: 12px;color: black;margin-top: 7px">经纬坐标</div>
      </div>
      <div @click="clickButton('实时天气')" class="buttonArea">
        <div class="iconfontFamily" style="font-size: 22px" :style="{color: showWeather ? 'rgba(94,143,255,1)' : 'rgba(166,166,166,1)'}">&#xe604;</div>
        <div style="font-size: 12px;color: black;margin-top: 7px">实时天气</div>
      </div>
      <div @click="clickButton('著名地标')" class="buttonArea">
        <div class="iconfontFamily" style="font-size: 22px" :style="{color: showMarker ? 'rgba(94,143,255,1)' : 'rgba(166,166,166,1)'}">&#xe607;</div>
        <div style="font-size: 12px;color: black;margin-top: 7px">著名地标</div>
      </div>
      <div @click="clickButton('纯净模式')" class="buttonArea">
        <div class="iconfontFamily" style="font-size: 22px" :style="{color: isClearMode ? 'rgba(94,143,255,1)' : 'rgba(166,166,166,1)'}">&#xe606;</div>
        <div style="font-size: 12px;color: black;margin-top: 7px">纯净模式</div>
      </div>
    </div>
  </div>
</template>

<script>

import {getMapboxToken} from "@/utils/utils";
import mapboxgl from "mapbox-gl";
import CiteMarkerEntry from "@/views/BubbleNews/CiteMarker/CiteMarkerEntry.vue";
import 'mapbox-gl/dist/mapbox-gl.css';
import TopShareBar from "@/components/TopShareBar.vue";
// import {Threebox} from 'threebox-plugin'
// import turf from "turf";
// import RainScene from "@/views/CityOfSKy/RainScene.vue";

export default {
  name: 'CityOfSky',
  // components: {RainScene, TopShareBar, CiteMarkerEntry},
  components: {TopShareBar, CiteMarkerEntry},
  data() {
    return {
      showLngLat: true,
      showWeather: true,
      showMarker: true,
      map: '',
      mapboxInitFinish: false,
      spotList: [],
      initCenter: [],
      initZoom: 0,
      initPitch: 0,
      initBearing: 0,
      currLng: 0,
      currLat: 0,
      movePoint: [],
      pointIdx: 1,
      lineLayerUrl: '',
      lineGeo: '',
      weather: '',
      mainLayerUrl: '',
      infoId: '',
      duration: 15000,
      isShare: false,
      title: '',
      // 地球动画相关
      mapAnimationName: '',
      flyBackAnimationTimer: '',
      flyBackDuration: 5000,
      // threeJS实现雨天天气相关，目前暂时不用
      // centerCoords: [],
      // rainRangeWidth: 5000,
      // rainRangeHeight: 5000,
    }
  },
  computed: {
    isClearMode: function () {
      return !this.showLngLat && !this.showWeather && !this.showMarker
    }
  },
  methods: {
    getMainSkyCastleById(infoId) {
      this.$http.get('/earthApi/main-sky-castle/getMainSkyCastleById?infoId=' + infoId).then((resp) => {
        resp = resp.data
        if (resp.status === 200) {
          resp = resp.data
          this.initBearing = resp['bearing']
          this.lineLayerUrl = resp['lineLayerUrl']
          this.lineGeo = resp['lineGeo']
          this.mainLayerUrl = resp['layerUrl']
          this.initPitch = resp['pitch']
          this.spotList = resp['spotList']
          this.weather = resp['weather']
          this.initZoom = resp['zoom']
          this.title = resp['title']
          document.title = this.title
          this.movePoint = JSON.parse(this.lineGeo)['coordinates']
          this.initCenter = this.movePoint[0]
          this.initMap()
        }
      })
    },
    async initMap() {
      await getMapboxToken()
      mapboxgl.accessToken = this.globalJS.mapboxToken;
      this.map = new mapboxgl.Map({
        container: 'map',
        center: this.initCenter,
        pitch: this.initPitch,
        zoom: this.initZoom,
        // minZoom: this.initZoom,
        // maxZoom: this.initZoom,
        bearing: this.initBearing,
        style: this.globalJS.mapboxStyle,
        preserveDrawingBuffer: true,
        localIdeographFontFamily: '',
        projection: {name: 'globe'},
        transformRequest: (url) => {
          if (url.indexOf('https://api.mapbox.com') > -1) {
            return {
              url: url.replace('https://api.mapbox.com', 'https://earth.jl1.cn/mapbox'),
            };
          }
        }
      })

      this.map.on('load', () => {
        const position = this.map.getCenter()
        this.currLng = position['lng'].toFixed(4)
        this.currLat = position['lat'].toFixed(4)
        // this.map.addSource('oneLayer', {
        //   type: 'raster',
        //   tiles: [
        //     'https://tile.charmingglobe.com/tile/china2022/tms/{z}/{x}/{y}?v=v1&token=Bearer%20a84a40c81f784490a4c5689187054abf'
        //   ],
        //   tileSize: 256,
        //   scheme: 'tms',
        // });
        // this.map.addLayer({
        //   id: 'oneLayer',
        //   type: 'raster',
        //   source: 'oneLayer',
        // })
        this.map.setFog({
          "horizon-blend": 0.1,
          "space-color": 'rgba(0, 0, 0, 1)',
          "star-intensity": 0.15
        });
        this.map.addLayer({
          id: 'sky',
          type: 'sky',
          paint: {
            'sky-type': 'atmosphere',
            'sky-atmosphere-sun': [0.0, 0.0],
            'sky-atmosphere-sun-intensity': 15,
          }
        })
        // 加载高分04图层
        this.map.addSource('mainCiteSource', {
          type: 'raster',
          tiles: [
              this.mainLayerUrl
          ],
          tileSize: 256,
          scheme: 'tms',
        })
        this.map.addLayer({
          id: 'mainCiteLayer',
          type: 'raster',
          source: 'mainCiteSource'
        })
        // 加载路线图层
        if (this.lineLayerUrl) {
          this.map.addSource('lineCiteSource', {
            type: 'raster',
            tiles: [
              this.lineLayerUrl
            ],
            tileSize: 256,
            scheme: 'tms',
          })
          this.map.addLayer({
            id: 'lineCiteLayer',
            type: 'raster',
            source: 'lineCiteSource'
          })
        }
        // this.setWindowThreeBox()
        this.mapboxInitFinish = true

        this.disableMapboxInteraction()
        this.easeToFunc()
        const rainy = ['Thunderstorm', 'Drizzle', 'Rain']
        const sunny = ['Clear']
        const cloudy = ['Clouds', 'Atmosphere']
        const snowy = ['Snow']
        if (sunny.includes(this.weather)) {
          this.sunWeather()
        } else if (cloudy.includes(this.weather)) {
          this.cloudWeather()
        } else if (rainy.includes(this.weather)) {
          // this.rainWeather()
          this.goodRainScene()
        } else if (snowy.includes(this.weather)) {
          this.snowWeather()
        }
      })
      this.map.on('move', () => {
        const position = this.map.getCenter()
        this.currLng = position['lng'].toFixed(4)
        this.currLat = position['lat'].toFixed(4)
      })
    },
    // setWindowThreeBox() {
    //   window.tb = new Threebox(
    //       this.map,
    //       this.map.getCanvas().getContext('webgl'),
    //       {
    //         defaultLights: true
    //       }
    //   )
    // },
    //多云天气
    cloudWeather() {
      // 获取画布和上下文
      const canvas = document.getElementById("weather");
      console.log(canvas)
      const ctx = canvas.getContext("2d");

      // 设置 Canvas 大小
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      const width = canvas.width
      const height = canvas.height

      //云朵图片
      const cloudImage = new Image();
      cloudImage.src = "https://market.obs.cn-north-4.myhuaweicloud.com:443/202412301129000123649.png";

      // 云朵数量 颜色
      class Cloud {
        constructor(image, x, y, speed, size) {
          this.x = x
          this.y = y
          this.image = image
          this.speed = speed
          this.size = size
        }

        update() {
          this.x += this.speed
          if(this.x > width + this.image.width / 2) {
            this.x = -this.image.width / 2
            this.size = 1 + Math.random() * 0.4
          }
        }

        draw() {
          // 绘制云朵
          const cloudW = this.image.width * this.size
          const cloudH = this.image.height * this.size
          ctx.drawImage(this.image, this.x - cloudW / 2, this.y - cloudH / 2, cloudW, cloudH);
        }
      }

      const clouds = []
      const NUM_CLOUDS = 2;

      //可以任意设定云朵大小、位置、移动速度等
      for (let i = 0; i < NUM_CLOUDS; i++) {
        const x = i * canvas.width / NUM_CLOUDS * 1.8
        const y = 200
        const speed = 0.3
        const size = 1
        const cloud = new Cloud(cloudImage, x, y, speed, size)
        clouds.push(cloud)
      }

      // 动画循环
      function loop() {
        // 清除画布
        ctx.clearRect(0, 0, width, height);

        // 绘制图像
        // ctx.save();
        // ctx.translate(cx, 0);
        // ctx.rotate(angle * Math.PI / 1800);
        // ctx.drawImage(img, -img.width / 2, -img.height / 2, img.width, img.height);
        // ctx.restore();

        clouds.forEach((_, i) => {
          clouds[i].draw(ctx);
          clouds[i].update();
        })

        // 更新旋转角度
        // angle += 0.5;

        // 循环动画
        requestAnimationFrame(loop);
      }

      // 加载图像后启动动画
      cloudImage.onload = function() {
        loop();
      };
    },
    //晴天天气
    sunWeather() {
      // 获取画布和上下文
      const canvas = document.getElementById("weather");
      const ctx = canvas.getContext("2d");

      // 设置 Canvas 大小
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      const width = canvas.width
      const height = canvas.height

      //图片
      const sunImage = new Image();
      sunImage.src = "https://market.obs.cn-north-4.myhuaweicloud.com:443/202412301438000516511.png";
      sunImage.width = 1500;
      sunImage.height = 1500;
      // 定义旋转角度和旋转中心
      let angle = 0;
      const cx = width + 150;
      const cy = - 150;

      // 动画循环
      function loop() {
        // 清除画布
        ctx.clearRect(0, 0, width, height);

        // 绘制图像
        ctx.save();
        ctx.translate(cx, cy);
        ctx.rotate(angle * Math.PI / 1800);
        ctx.drawImage(sunImage, -sunImage.width / 2, -sunImage.height / 2, sunImage.width, sunImage.height);
        ctx.restore();

        // 更新旋转角度
        angle += 0.5;

        // 循环动画
        requestAnimationFrame(loop);
      }

      // 加载图像后启动动画
      sunImage.onload = function() {
        loop();
      };
    },
    //雨天气
    rainWeather() {
      // 获取画布和上下文
      const canvas = document.getElementById("weather");
      const ctx = canvas.getContext("2d");

      // 设置 Canvas 大小
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      const width = canvas.width
      const height = canvas.height

      //雨图片
      const rainImage1 = new Image();
      rainImage1.src = "https://market.obs.cn-north-4.myhuaweicloud.com:443/202412301543000322231.png";
      rainImage1.width = 3;
      const rainImage2 = new Image();
      rainImage2.src = "https://market.obs.cn-north-4.myhuaweicloud.com:443/202412301545000187919.png";
      rainImage2.width = 2;
      rainImage2.height = 60 + Math.random() * 10;

      // 雨丝数量 颜色
      class Rain {
        constructor(image, x, y, speed, size) {
          this.x = x
          this.y = y
          this.image = image
          this.speed = speed
          this.size = size
        }

        update() {
          this.y += this.speed
          if(this.y > height + this.image.height / 2) {
            this.y = -this.image.height / 2
            this.size = 1 + Math.random() * 0.4
          }
        }

        draw() {
          // 绘制雨丝
          const rainW = this.image.width * this.size
          const rainH = this.image.height * this.size
          ctx.drawImage(this.image, this.x - rainW / 2, this.y - rainH / 2, rainW, rainH);
        }
      }

      const rains = []
      const NUM_RAINS = 300;

      //可以任意设定云朵大小、位置、移动速度等
      for (let i = 0; i < NUM_RAINS; i++) {
        const x = Math.random() * width
        const y = Math.random() * height
        const speed = 5 + Math.random() * 10
        const size = 1
        const rainType = Math.random();
        const rain = new Rain(rainType > 0.3 ? rainImage1 : rainImage2, x, y, speed, size)
        rains.push(rain)
      }

      // 动画循环
      function loop() {
        // 清除画布
        ctx.clearRect(0, 0, width, height);

        rains.forEach((_, i) => {
          rains[i].draw(ctx);
          rains[i].update();
        })

        // 循环动画
        requestAnimationFrame(loop);
      }

      // 加载图像后启动动画
      rainImage1.onload = function() {
        rainImage2.onload = function() {
          loop();
        };
      };
    },
    //雪天气
    snowWeather() {
      // 获取画布和上下文
      const canvas = document.getElementById("weather");
      const ctx = canvas.getContext("2d");

      // 设置 Canvas 大小
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      const width = canvas.width
      const height = canvas.height

      //雪图片
      const snowImage = new Image();
      snowImage.src = "https://market.obs.cn-north-4.myhuaweicloud.com:443/202412301655000098037.png";

      // 雪花数量 颜色
      class Snow {
        constructor(image, x, y, speed, size) {
          this.x = x
          this.y = y
          this.image = image
          this.speed = speed
          this.size = size
        }

        update() {
          if(this.speed > 1){
            this.x += Math.random() * 0.5
          }
          else{
            this.x -= Math.random() * 0.5
          }
          this.y += this.speed
          if(this.x > width + this.image.width / 2 || this.y > height + this.image.height / 2) {
            this.x = Math.random() * width
            this.y = -this.image.height / 2
            this.size = 0.1 + Math.random() * 0.3
          }
        }

        draw() {
          // 绘制雪花
          const snowW = this.image.width * this.size
          const snowH = this.image.height * this.size
          ctx.drawImage(this.image, this.x - snowW / 2, this.y - snowH / 2, snowW, snowH);
        }
      }

      const snows = []
      const NUM_SNOWS = 50;

      //可以任意设定雪花大小、位置、移动速度等
      for (let i = 0; i < NUM_SNOWS; i++) {
        const x = Math.random() * width
        const y = Math.random() * height
        const speed = 0.5 + Math.random()
        const size = 0.1 + Math.random() * 0.3
        const snow = new Snow(snowImage, x, y, speed, size)
        snows.push(snow)
      }

      // 动画循环
      function loop() {
        // 清除画布
        ctx.clearRect(0, 0, width, height);

        snows.forEach((_, i) => {
          snows[i].draw(ctx);
          snows[i].update();
        })

        // 循环动画
        requestAnimationFrame(loop);
      }

      // 加载图像后启动动画
      snowImage.onload = function() {
        loop();
      };
    },
    easeToFunc() {
      if (this.pointIdx !== 0 && this.pointIdx % this.movePoint.length === 0) {
        // 地球动画
        this.mapAnimationName = 'mapOpacityChange'
        clearTimeout(this.flyBackAnimationTimer)
        this.flyBackAnimationTimer = setTimeout(() => {
          this.mapAnimationName = ''
        }, this.flyBackDuration)
        this.map.flyTo({
          center: this.movePoint[0],
          zoom: this.initZoom,
          bearing: this.initBearing,
          pitch: this.initPitch,
          curve: 1,
          duration: this.flyBackDuration,
          easing: (t) => {
            if (t === 1) {
              this.pointIdx = this.movePoint.length + 1
              setTimeout(() => {
                this.easeToFunc()
              })
            }
            return t
          }
        })
      } else {
        this.map.easeTo({
          center: this.movePoint[this.pointIdx % this.movePoint.length],
          zoom: this.initZoom,
          bearing: this.initBearing,
          pitch: this.initPitch,
          curve: 1,
          duration: this.duration,
          easing: (t) => {
            if (t === 1) {
              this.pointIdx++
              setTimeout(() => {
                this.easeToFunc()
              }, 100)
            }
            return t
          }
        })
      }
    },
    clickButton(type) {
      switch (type) {
        case '经纬坐标':
          this.showLngLat = !this.showLngLat;
          break;
        case '实时天气':
          this.showWeather = !this.showWeather;
          break;
        case '著名地标':
          this.showMarker = !this.showMarker;
          if (this.map.getLayer('lineCiteLayer')) {
            if (this.showMarker) {
              this.map.setLayoutProperty('lineCiteLayer','visibility','visible')
            } else {
              this.map.setLayoutProperty('lineCiteLayer','visibility','none')
            }
          }
          break;
        case '纯净模式':
          this.showLngLat = false;
          this.showWeather = false;
          this.showMarker = false;
          if (this.map.getLayer('lineCiteLayer')) {
            this.map.setLayoutProperty('lineCiteLayer','visibility','none')
          }
          break;
      }
    },
    // getDistance(coordsA, coordsB) {
    //   return turf.distance(coordsA, coordsB)
    // },
    disableMapboxInteraction() {
      this.map.scrollZoom.disable(); // 禁止缩放
      this.map.boxZoom.disable(); // 禁止框选缩放
      this.map.dragRotate.disable(); // 禁止拖动
      this.map.keyboard.disable(); // 禁止键盘交互
      this.map.dragPan.disable(); // 禁止拖动
      this.map.doubleClickZoom.disable(); // 禁止双击缩放
      this.map.touchZoomRotate.disableRotation(); // 禁用触摸旋转
      this.map.touchPitch.disable()
    },

    goodRainScene() {
      //drop
      let dropList = [];
      let gravity = 0.5;
      //
      let linelist = [];
      let canvasEl = document.getElementById('weather');
      let ctx = canvasEl.getContext('2d');
      let mousePos = [0, 0];
      let backgroundColor = '#0000';
      canvasEl.width = canvasEl.clientWidth;
      canvasEl.height = canvasEl.clientHeight;
      let speedx = 0;
      let maxspeedx = 0;
      // function getRgb(r, g, b) {
      //   return "rgb(" + r + "," + g + "," + b + ")";
      // }
      // 当 window 窗口大小发生改变的时候，重置 canvas 画布的大小
      window.onresize = function () {
        canvasEl.width = canvasEl.clientWidth;
        canvasEl.height = canvasEl.clientHeight;
      }
      // // 当鼠标进入 window 窗口时，获取鼠标的位置
      // window.onmousemove = function (e) {
      //   mousePos[0] = e.clientX;
      //   mousePos[1] = e.clientY;
      //   maxspeedx = (e.clientX - canvasEl.clientWidth / 2) / (canvasEl.clientWidth / 2);
      // }

      function createLine(e) {
        let temp = 0.25 * (50 + Math.random() * 100);
        let myline = {
          speed: 5 * (Math.random() * 6 + 3),
          die: false,
          posx: e,
          posy: -200,
          h: temp,
          // color: getRgb(Math.floor(temp * 255 / 75), Math.floor(temp * 255 / 75), Math.floor(temp * 255 / 75))
          color: 'rgba(255, 255, 255, 1)'
        };
        linelist.push(myline);
      }
      window.requestAnimationFrame(update);
      function createDrop(x, y) {
        let mydrop = {
          die: false,
          posx: x,
          posy: y,
          vx: (Math.random() - 0.5) * 8,
          vy: Math.random() * (-6) - 3,
          radius: Math.random() * 1.5 + 1
        };
        return mydrop;
      }

      function madedrops(x, y) {
        let maxi = Math.floor(Math.random() * 5 + 5);
        for (let i = 0; i < maxi; i++) {
          dropList.push(createDrop(x, y));
        }
      }

      function update() {
        if (dropList.length > 0) {
          dropList.forEach(function (e) {
            e.vx = e.vx + (speedx) / 2;
            e.posx = e.posx + e.vx;
            e.vy = e.vy + gravity;
            e.posy = e.posy + e.vy;
            if (e.posy > canvasEl.clientHeight) {
              e.die = true;
            }
          });
        }
        for (let i = dropList.length - 1; i >= 0; i--) {
          //delite die
          if (dropList[i].die) {
            dropList.splice(i, 1);
          }
        }

        speedx = speedx + (maxspeedx - speedx) / 50;

        if (Math.random() > 0) {
          createLine(Math.random() * 2 * canvasEl.width - (0.5 * canvasEl.width));
          createLine(Math.random() * 2 * canvasEl.width - (0.5 * canvasEl.width));
          createLine(Math.random() * 2 * canvasEl.width - (0.5 * canvasEl.width));
        }
        let mydeadline = canvasEl.clientHeight - Math.random() * canvasEl.clientHeight / 5;
        linelist.forEach(function (e) {
          let dis = Math.sqrt(((e.posx + speedx * e.h) - mousePos[0]) * ((e.posx + speedx * e.h) - mousePos[0]) + (e.posy + e.h - mousePos[1]) * (e.posy + e.h - mousePos[1]));
          if (dis < 35) {
            madedrops(e.posx + speedx * e.h, e.posy + e.h);
            e.die = true;
          }

          if ((e.posy + e.h) > mydeadline) {
            if (Math.random() > 0.85) {
              madedrops(e.posx + speedx * e.h, e.posy + e.h);
              e.die = true;
            }
          }
          if (e.posy >= canvasEl.clientHeight) {
            e.die = true;
          } else {
            e.posy = e.posy + e.speed;
            e.posx = e.posx + (e.speed * speedx);
          }
        });
        for (let i = linelist.length - 1; i >= 0; i--) {
          if (linelist[i].die) {
            linelist.splice(i, 1);
          }
        }
        render();
        window.requestAnimationFrame(update);
      }
      function render() {
        ctx.clearRect(0, 0, canvasEl.width, canvasEl.height)
        ctx.fillStyle = backgroundColor;
        ctx.fillRect(0, 0, canvasEl.width, canvasEl.height);

        linelist.forEach(function (line) {
          ctx.strokeStyle = line.color;
          ctx.beginPath();
          ctx.moveTo(line.posx, line.posy);
          ctx.lineTo(line.posx + speedx * line.h, line.posy + line.h);
          ctx.stroke();
        });
        ctx.lineWidth = 1;
        ctx.strokeStyle = "#fff";
        dropList.forEach(function (e) {
          ctx.beginPath();
          ctx.arc(e.posx, e.posy, e.radius, Math.random() * Math.PI * 2, 1 * Math.PI);
          ctx.stroke();
        });
      }
    },
    // 移动端底栏浏览器高度会遮挡 需要先获取浏览器可用高度信息
    getWindowConfig() {
      let windowWidth = window.innerWidth;
      let windowHeight = window.innerHeight;
      if (typeof windowWidth !== 'number') {
        if (document.compatMode === 'CSS1Compat') {
          windowWidth = document.documentElement.clientWidth;
          windowHeight = document.documentElement.clientHeight;
        } else {
          windowWidth = document.body.clientWidth;
          windowHeight = window.body.clientHeight;
        }
      }
      return {
        windowWidth: windowWidth,
        windowHeight: windowHeight
      }
    },
  },
  mounted() {
    if (this.$route.query.share === '1') {
      this.isShare = true
    }
    if (this.$route.query.infoId) {
      this.infoId = this.$route.query.infoId
    }
    this.$refs.cityOfSkyPage.style.height = `${this.getWindowConfig().windowHeight}px`
    this.getMainSkyCastleById(this.infoId)
  },
}
</script>

<style scoped>
.buttonArea {
  height: 45px;
  width: calc(25vw - 6px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/deep/ .mapboxgl-ctrl {
  display: none !important;
}

</style>
<style>
@keyframes mapOpacityChange {
  0% {opacity:1;}
  45% {opacity:0.02;}
  55% {opacity:0.02;}
  100% {opacity:1;}
}
@keyframes swing {
  0% {
    transform: rotate(-10deg); /* 向左倾斜10度 */
  }
  50% {
    transform: rotate(10deg);  /* 向右倾斜10度 */
  }
  100% {
    transform: rotate(-10deg);  /* 向右倾斜10度 */
  }
}
</style>