<template>
  <div ref="markerDom" style="display: flex;flex-direction: column;justify-content: center;align-items: center;position: absolute;top: -25px;">
    <div class="textArea" style="position: relative;top: -7px;">
      <p style="color: white" class="alibabaPuHuiTiFontFamily">{{ item.spot }}</p>
    </div>
    <img height="30" src="../../../assets/CityOfSky/marker.png"/>
    <div style="width: 33px;height: 15px;background: rgba(255,128,0,1);filter: blur(13px)">

    </div>
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl";

export default {
  name: 'CiteMarker',
  props: {
    item: {
      require: true,
    },
    map: {
      require: true,
    }
  },
  data() {
    return {
      marker: '',
    }
  },
  methods: {
    addToMap() {
      this.marker = new mapboxgl.Marker(this.$refs.markerDom)
          .setLngLat([this.item.lon, this.item.lat])
          .addTo(this.map)
    }
  },
  mounted() {
    this.addToMap()
  },
  beforeDestroy() {
    this.marker.remove()
  }
}
</script>

<style scoped>
.textArea {
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
  background: linear-gradient(90deg, rgba(255, 123, 0, 1) 0%, rgba(244, 173, 25, 1) 100%);
  border-radius: 5px;
  border: 1px solid white;
  box-shadow: 1.71px 1.71px 1.71px rgba(154, 192, 234, 1), 2.84px 2.84px 2.27px rgba(154, 192, 234, 0.25);
}
</style>