<!--后台获取图片验证码-->
<template>
  <div :style="{'display':otherShowParam ? display : 'none'}" id="picCaptcha" style="width: calc(100% - 30px);height: 40px;margin-top: 20px;margin-left: 15px;">
    <div style="display:flex;">
      <div style="flex:1;">
        <input @input="onCaptchaInputChanged" v-model="$store.state.picCaptcha" placeholder="请输入图形验证码" id="picCaptchaInput">
        <div style="width: 100%;height: 1px;background: rgba(151, 151, 151, 1);"></div>
        <div v-show="$store.state.picCaptchaError" style="margin-left:9px;color: #ff5252;font-size: 12px" >验证码有误，请重新输入</div>
      </div>
      <div style="width:110px;margin-left: 12px;">
        <img @click="getPicCaptcha" :src="picCaptchaSrc" style="width:90px;">
      </div>
    </div>

  </div>

</template>

<script>
export default {
  name: "InputPicCaptcha",
  data(){
    return{
      //验证码行是否显示
      display:"none",

      //验证码检查规范
      requiredRule:[v => !!v || 'E-mail is required'],

      //生成图形验证码
      picCaptchaSrc:"",



    }

  },
  props:{
    phone:{default:""},
    prefix:{default:""},
    otherShowParam:{default:true},   //其他显隐相关参数
  },
  watch:{
    //监听父组件传参的+86和手机号变化
    phone(){
      this.checkShow();
    },
    prefix(){
      this.checkShow();
    },
    otherShowParam(newData, oldData){
      console.log(newData,oldData)
      this.checkShow();
    }
  },
  methods:{
    //获取图形验证码
    getPicCaptcha(){
      let formData = new FormData()
      formData.append('phone',this.phone)
      formData.append('prefix',"+" + this.prefix)
      this.$http.post('/userApi/getPhonePicCaptcha', formData).then(resultData => {
        resultData = resultData.data;
        console.log(resultData)
        if(resultData.code === 200){
          this.picCaptchaSrc = resultData.data.picture;
        }
      })
    },

    //填写时验证码错误提示隐藏
    onCaptchaInputChanged(){
      this.$store.state.picCaptchaError = false
    },


    //检查是否需要展示验证码行
    checkShow(){
      console.log(this.otherShowParam)
      if((this.prefix === '86' ? this.phone.length === 11 : !!this.phone) && this.otherShowParam){
        console.log(this.otherShowParam)
        //手机号合法，展示
        this.display = "block";
        this.getPicCaptcha();
      }
      else{
        //手机号不合法
        this.display = "none";
        this.$store.state.picCaptcha = "";
        this.$store.state.picCaptchaError = false
      }
    }


  },

  mounted(){
    console.log(this.otherShowParam)
  }


}
</script>

<style scoped>
#picCaptchaInput{
  border: none;
  outline: none;
  line-height: 37px;
  margin-left: 9px;
  font-size: 14px;
  width: 80%;
}
</style>