<template>
  <div>
    <div v-for="(item, idx) in markerList" :key="idx">
      <CiteMarker :item="item" :map="map"></CiteMarker>
    </div>
  </div>
</template>

<script>

import CiteMarker from "@/views/BubbleNews/CiteMarker/CiteMarker.vue";

export default {
  name: 'CiteMarkerEntry',
  components: {CiteMarker},
  props: {
    map: {
      require: true
    },
    markerList: {
      require: true
    },
  },
}
</script>

<style scoped>

</style>