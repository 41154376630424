
export default{
    //2020全国一张图路径
    // china1mUrl_2020:"https://tile.charmingglobe.com/map/china1m/{z}/{x}/{y}?v=v1&token=Bearer a84a40c81f784490a4c5689187054abf",
    //2021全国一张图路径
    // china1mUrl_2021:"https://tile.charmingglobe.com/tile/world/tms/{z}/{x}/{y}?v=v1&token=Bearer a84a40c81f784490a4c5689187054abf",
    //2021全球一张图路径
    world1mUrl_2021:"https://tile.charmingglobe.com/tile/world2021/tms/{z}/{x}/{y}?v=v1&token=Bearer 8bdba212ae5398db7063ebe72366bca1",
    //2022全国一张图路径
    china1mUrl_2022:"https://tile.charmingglobe.com/tile/china2022/tms/{z}/{x}/{y}?v=v1&token=Bearer 027f6004c4efb4cc3bc409334e7481a3",

    //图层访问路径，后面跟图层名及后续信息
    //目前未修改的功能已作废
    charmingglobeLayerUrl:"https://tile.charmingglobe.com/map/dataset/",


    mapboxToken:"pk.eyJ1Ijoid3l6d2FuZ3lhemhvdSIsImEiOiJjbGc0Z3RiN3Qwa3lhM3JzYWFmdGdsaWtvIn0.g8f6kkGmPIkD1mXGRO9Q2g",
    mapboxStyle:"mapbox://styles/wyzwangyazhou/clg4h4ow5000501o1xxipdmww"


}
